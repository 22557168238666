import React from 'react'
import packagesStyles from './purchase.module.scss'

const Packages = (props) => {

    return (
        <section className={packagesStyles.packages}>

            <div className={packagesStyles.packages__hollywood}>

                <div className={"container " + packagesStyles.packages__container}>

                    <div className={packagesStyles.packages__container__content}>
                        {props.children}
                    </div>

                </div>

            </div>

            <div className={packagesStyles.packages__wave}></div>

        </section>
    )
}

export default Packages