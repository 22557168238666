import React from 'react'
import panelStyles from './panel.module.scss'
import { StaticImage } from "gatsby-plugin-image"

const Panel = (props) => {

    return (
        <section className={panelStyles.panel}>
            <div className={panelStyles.panel__container}>

                <div className={panelStyles.panel__picture}>
                    <div className={panelStyles.panel__picture__image}>
                        <StaticImage 
                            src="../images/features/panel-comp.jpg" 
                            alt="cPanel Control Panel logo" 
                            width={678}
                        />
                    </div>
                </div>

                <div className={panelStyles.panel__content}>
                    <div className={panelStyles.panel__text}>
                        {props.children}
                    </div>
                </div>

                
            </div>
        </section>
    )
}

export default Panel