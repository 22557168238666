import React from 'react'
import securityStyles from './security.module.scss'
import { StaticImage } from "gatsby-plugin-image"

const Panel = (props) => {

    return (
        <section className={securityStyles.security}>
            <div className={securityStyles.security__container}>

                <div className={securityStyles.security__picture}>
                    <div className={securityStyles.security__picture__image}>
                        <StaticImage
                            src="../images/features/security-comp.jpg"
                            alt="Security Image"
                            width={678}
                        />
                    </div>
                </div>

                <div className={securityStyles.security__content}>
                    <div className={securityStyles.security__text}>
                        {props.children}
                    </div>
                </div>

                
            </div>
        </section>
    )
}

export default Panel