import React from 'react'
import ecoStyles from './eco.module.scss'
import JSONData from "./../data/eco.json"
import ecologi from '../images/climate-positive-workforce.png'

const Eco = (props) => {

    return (
        <section className={ecoStyles.eco} id="sustainability">
            <div className={"container " + ecoStyles.eco__container}>

                <div className={ecoStyles.eco__text}>
                    <h2><span>Sustainability</span></h2>

                    <p>Supporting the best climate action, every month we aim to give more back to the planet than we take and make additional contributions on behalf of our new customers. So far in 2021, we have planted <strong>{JSONData.trees} trees</strong> and have offset <strong>{JSONData.carbonOffset.toFixed(2)} tonnes</strong> of CO<sub>2</sub>e!</p>
                </div>

                <div className={ecoStyles.eco__image}>
                    <a href="https://ecologi.com/host3" target="_blank"><img src={ecologi} alt="Climate positive workforce"></img></a>
                </div>
            </div>
        </section>
    )
}

export default Eco