import React from 'react'
import uptimeStyles from './uptime.module.scss'

const Uptime = (props) => {

    return (
        <section className={uptimeStyles.uptime}>
            <div className={"container " + uptimeStyles.uptime__container}>

                <div className={uptimeStyles.uptime__text}>
                    {props.children}
                </div>

                <div className={uptimeStyles.uptime__pulse}>
                    <div className={uptimeStyles.uptime__pulse__main}></div>
                </div>
            </div>
        </section>
    )
}

export default Uptime