import React from 'react'
import speedStyles from './speed.module.scss'

const Speed = (props) => {

    return (
        <section className={speedStyles.speed}>
            <div className={"container " + speedStyles.speed__container}>

                <div className={speedStyles.speed__title}>
                    <h2 className="hintro" dangerouslySetInnerHTML={{ __html: props.title }} />
                </div>

                <div className={speedStyles.speed__text}>
                    {props.children}
                </div>

                <div className={speedStyles.speed__results}>
                    <div className={speedStyles.speed__results__table}>
                        <div className={speedStyles.speed__results__table__item}>
                            <p><span>Host3 Servers</span><i>12x</i></p>
                            <p><span>Conventional</span><i>1x</i></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Speed