import React from 'react'
import migrationStyles from './migrations.module.scss'

const Migrations = (props) => {

    return (
        <section className={migrationStyles.migration}>
            <div className={"container " + migrationStyles.migration__container}>

                <div className={migrationStyles.migration__text}>
                    {props.children}
                </div>

            </div>
        </section>
    )
}

export default Migrations