import React from 'react'

import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import Panel from '../components/panel'
import Uptime from '../components/uptime'
import Speed from '../components/speed'
import Security from '../components/security'
import Migrations from '../components/migrations'
import Eco from '../components/eco'
import Purchase from '../components/purchase'
import Logos from '../components/logos'
import Talk from '../components/lets-talk'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const Feature = () => {
    return (
        <Store>
            <Head title="Why" />
            <Navigation />
            <Header disable/>
            
            <Speed title="Industry-Leading <span>Speed</span>">
                <p>Not all hosting servers are equal, our servers are up to <strong>12x faster for running WordPress</strong> than your conventional server* and our storage is <strong>2x faster</strong> than the industry standard**</p>

                <p>*Host3 <i>Standard</i> and <i>High Availability</i> packages with LiteSpeed and LSCache vs Apache and W3 Total Cache. **Sysbench read and write storage performance vs DigitalOcean and AWS EC2</p>
            </Speed>
            <Panel>
                <h2>World's Best <span>Control Panel</span></h2>

                <p>The heart of your website, we proudly offer <strong>cPanel</strong> on our ready-made cloud solutions, offering a vast array of features, ease of use, reliability, and backed by over <i>20 years of experience,</i> we believe nothing comes close.</p>
            </Panel>
            <Uptime>
                <h2>Always <span>Online</span></h2>

                <p>Our infrastructure is designed with a primary goal, <strong>keeping your website online</strong>.</p>

                <p>With our High Availability packages, we continuously monitor your website and if any issues are detected we route your website to a healthy server in as little as <strong>15 seconds</strong>, this means we can keep your website online no matter the situation whether it be a data center outage or planned maintenance.</p>
            </Uptime>
            <Security>
                <h2>Security. <span>Check</span></h2>

                <p>All of our packages feature a secure <strong>HTTPS (SSL) certificate</strong>, Cloudflare support, and script-security hardening as standard, giving you <i>best-in-class</i> security and protection from attacks without compromise to performance.</p>
            </Security>
            <Migrations>
                <h2>Free <span>Transfers</span></h2>

                <p>Host3 offers a free migration service to all of our cloud solutions with <strong>no prerequisites</strong>, whether it's 1 or 100 websites, if it runs on our servers, we will transfer everything over and make sure it runs perfectly.</p>
            </Migrations>
            <Eco/>
            <Purchase>
                
            </Purchase>
            <Logos/>
            <Talk/>
            <Footer/>
            <Cookies />
        </Store>
    )
}

export default Feature
