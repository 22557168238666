import React from 'react'
import logoStyles from './logos.module.scss'
import { StaticImage } from "gatsby-plugin-image"

const Logos = (props) => {

    return (
        <section className={logoStyles.logos}>

            <ul>
                <li><StaticImage
                    src="../images/logos/cpanel-comp.png"
                    alt="cPanel Control Panel logo"
                    width={120}
                    placeholder="tracedSVG"
                /></li>
                <li><StaticImage
                    src="../images/logos/cloudflare-comp.png"
                    alt="Cloudflare logo"
                    height={40}
                    placeholder="tracedSVG"
                /></li>
                <li><StaticImage
                    src="../images/logos/aws-comp.png"
                    alt="Amazon Web Services logo"
                    height={40}
                    placeholder="tracedSVG"
                /></li>
                <li><StaticImage
                    src="../images/logos/litespeed.png"
                    alt="Litespeed logo"
                    width={140}
                    placeholder="tracedSVG"
                /></li>
                <li><StaticImage
                    src="../images/logos/google-cloud.png"
                    alt="Google Cloud"
                    height={40}
                    placeholder="tracedSVG"
                /></li>
            </ul>

        </section>
    )
}

export default Logos